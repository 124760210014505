<template>
	<el-dialog 
		title="货车轨迹" 
		top="1vh" width="90%" 
		:visible.sync="is_show_in_page" 
		@close="close"
	> 
	<div style="margin-bottom:10px;">
		 <el-button v-if="user_info.tel!='19155606858'" @click="get_point_list('get_path_data_daka')"  plain>app打卡轨迹</el-button>
		 <el-button @click="get_point_list('get_path_data_zj')" plain>北斗轨迹</el-button>
		 <el-button v-if="user_info.tel!='19155606858'" @click="get_point_list('get_path_data_gd')"  plain>高德导航</el-button>
		 <el-button v-if="user_info.tel!='19155606858'" @click="get_point_list('get_path_data_ts')" plain>客户推送轨迹</el-button>
	</div>
	<div class="head-head">
		<span>车牌号：{{truck_plate_num}}</span>
		<span>发车时间：{{start_time_text}}</span>
		<span>到货时间：{{end_time_text}}</span>
	</div>
       <div style="display: flex;">
		<div id="container"  style="width:70%" v-if="tabPosition == 1" :style="{height:map_height+'px'}"></div>
		<div>
			 <el-timeline :reverse="reverse">
				<el-timeline-item
				v-for="(activity, index) in trajectory_list"
				:key="index"
				:timestamp="activity.time_text">
				{{activity.addrs}}
				</el-timeline-item>
			</el-timeline>
		</div>
	   </div>
		
		<!-- <div v-if="tabPosition == 2"> -->
		<!-- 表格 -->
		<!-- 添加列表 -->
		<trakectoyList
			:is_show="is_show"
			:trajectory_list="trajectory_list"
		></trakectoyList>
	</el-dialog>
</template>

<script>
	import trakectoyList from './trakectoy_list.vue'
	import { Loading } from 'element-ui';
	import {mapState} from 'vuex'
	export default {
		components:{
			trakectoyList
		},
		props:{
			case_county:String,
			aim_county:String,
			start_time:Number,
			end_time:Number,
			truck_plate_num:String,
			aim_lngLnt:Object,
			case_lngLnt:Object,
			driver_info:Object,
			truck_tord_num:String
		},

		data() {
			return {
				marker_list:[],//轨迹点
				point_list:[],
				map_height:0,
				is_show_in_page:false,
				tabPosition:1,
				trajectory_list:[],//轨迹列表
				start_time_text:'',
				end_time_text:'',
				is_show:0,

				reverse: true,
				activities: [{
				content: '活动按期开始',
				timestamp: '2018-04-15'
				}, {
				content: '通过审核',
				timestamp: '2018-04-13'
				}, {
				content: '创建成功',
				timestamp: '2018-04-11'
				}]
			};
		},
		created() {
			//发货地县区
			if(!this.case_county || !this.case_county.trim()){
				this.$my.other.msg({
					type:'warning',
					str:'缺少发货地县区'
				});
				this.$emit('close')
				return;
			}

			//到货地县区
			if(!this.aim_county || !this.aim_county.trim()){
				this.$my.other.msg({
					type:'warning',
					str:'缺少到货地县区'
				});
				this.$emit('close')
				return;
			}

			//车牌号
			if(!this.$my.check.is_plate_num(this.truck_plate_num)){
				this.$my.other.msg({
					type:'warning',
					str:'缺少车牌号'
				});
				this.$emit('close')
				return;
			}
			//开始时间
			if(!this.$my.check.is_num(this.start_time) || this.start_time<=0){
				this.$my.other.msg({
					type:'warning',
					str:'轨迹开始时间必须是数字'
				});
				this.$emit('close')
				return;
			}

			//结束时间
			if(!this.$my.check.is_num(this.end_time) || this.end_time<=0){
				this.$my.other.msg({
					type:'warning',
					str:'轨迹结束时间必须是数字'
				});
				this.$emit('close')
				return;
			}

			//车牌号
			if(!this.$my.check.is_plate_num(this.truck_plate_num)){
				this.$my.other.msg({
					type:'warning',
					str:'缺少车牌号'
				});
				this.$emit('close')
				return;
			}
			this.start_time_text =this.$my.other.totime(this.start_time);
			this.end_time_text =this.$my.other.totime(this.end_time);
			//打开弹出层
			this.is_show_in_page=true;

			this.init()
		},
	    computed:{
			...mapState(['user_info'])
		},
		mounted(){
			
		},
		methods: {
			trajectory_for(item,n){
				let date = item[n]
				let that = this
				let addrs = ''
				//线列表
				let every_length=''
				if(item.length>10){
					every_length=parseInt(item.length/200)
				}else{
					every_length=parseInt(item.length)
				}
				if(n ==item.length){
					let loadingInstance = Loading.service(this.options);
						this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
						loadingInstance.close();
					});
					// this.is_show ++
					return
				}
				//经纬度转换详细地址
				const geocoder = new AMap.Geocoder();
				geocoder.getAddress([date.lon, date.lat], (status, result) => {
					if (status === 'complete' && result.info === 'OK') {
						addrs = result.regeocode.formattedAddress;
						// if(n%every_length==0){
							that.trajectory_list.push({
								addrs:addrs,
								time_text:that.$my.other.totime(date.timestamp)
							})
							// console.log(that.trajectory_list)
						//}
						n++
						that.trajectory_for(item,n)
					} else {
						console.log('Failed to get the address information');
						n++
						that.trajectory_for(item,n)
					}
				});
			},
			//切换
			trajectory_type(n){
				let loadingInstance = Loading.service(this.options);
					this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
					loadingInstance.close();
				});
				this.trajectory_for(this.marker_list,n)
			},
			init(){
				//地图高度
				this.map_height=document.documentElement.clientHeight*0.8;
				window.onresize = () => {
					this.map_height=document.documentElement.clientHeight*0.8;
				}

				//加载地图
				this.$amap.load({
					plugins:[
						'AMap.DistrictSearch',
					]
				}).then((AMap)=>{

					//
					this.AMap=AMap;

					//地图初始化
					this.map=new AMap.Map("container",{

						resizeEnable: true,

						//初始化地图级别
						zoom:10,
					});

					//发到货地区划
					this.show_district({
						case_county:this.case_county,
						aim_county:this.aim_county,
					});
					this.get_point_list('get_path_data_daka')
					//读取轨迹
				
					// this.get_point_list({
					// 	start_time:this.start_time,
					// 	end_time:this.end_time,
					// 	truck_plate_num:this.truck_plate_num,
					// });
				})
			},
			//初始化地图
			init1(){
				//地图高度
				this.map_height=document.documentElement.clientHeight*0.8;
				window.onresize = () => {
					this.map_height=document.documentElement.clientHeight*0.8;
				}

				//加载地图
				this.$amap.load({
					plugins:[
						'AMap.DistrictSearch',
					]
				}).then((AMap)=>{

					//
					this.AMap=AMap;

					//地图初始化
					this.map=new AMap.Map("container",{

						resizeEnable: true,

						//初始化地图级别
						zoom:10,
					});

					//发到货地区划
					this.show_district({
						case_county:this.case_county,
						aim_county:this.aim_county,
					});
					//读取轨迹
				
					// this.get_point_list({
					// 	start_time:this.start_time,
					// 	end_time:this.end_time,
					// 	truck_plate_num:this.truck_plate_num,
					// });
				})
			},
			//发到货地区划
			show_district(para){

				//取出地图对象
				let AMap = this.AMap;

				//实例化搜索对象
				let ser_obj=new AMap.DistrictSearch({
					level:'district',
					extensions:'all',
					subdistrict:0,
				});
				//搜索发货地
				ser_obj.search(para.case_county,(status,result)=>{
					
					if(status!='complete'){
						this.$my.other.msg({
							type:'warning',
							str:'搜索发货地失败'
						});
						return;
					}
					let bounds = ''
					//显示区划
					if(para.case_county == '洋县'){
						bounds=result.districtList[1].boundaries
					}else{
						bounds=result.districtList[0].boundaries
					}
					
					if(bounds){

						var polygons=[]
						for(var i=0;i<bounds.length;i++){

							//生成行政区划polygon
							var polygon=new AMap.Polygon({
								map: this.map,
								strokeWeight: 1,
								path: bounds[i],
								fillOpacity: .4,
								fillColor: '#CCF3FF',
								strokeColor: '#CC66CC'
							})
							polygons.push(polygon)
						}

						// 地图自适应
						this.map.setFitView()
					}
				})
				//搜索到货地
				ser_obj.search(para.aim_county,(status,result)=>{
					if(status!='complete'){
						this.$my.other.msg({
							type:'warning',
							str:'搜索到货地失败'
						});
						return;
					}
					let bounds = ''
					result.districtList.forEach(item=>{
						if(item.name == para.aim_county){
							bounds = item.boundaries
						}
					})
					//显示区划
					// =result.districtList[0].boundaries
					if(bounds){

						var polygons=[]
						for(var i=0;i<bounds.length;i++){

							//生成行政区划polygon
							var polygon=new AMap.Polygon({
								map: this.map,
								strokeWeight: 1,
								path: bounds[i],
								fillOpacity: .4,
								fillColor: '#CCF3FF',
								strokeColor: '#CC66CC'
							})
							polygons.push(polygon)
						}

						// 地图自适应
						this.map.setFitView()
					}
				})
			},

			//读取轨迹
			get_point_list(ctr){
				this.trajectory_list = []
				this.marker_list = []
				//初始化地图
				this.init1()
				//初始化
				Loading.service(this.options);
				//调接口
				this.$my.net.req({
					take_over_control:1,
					data:{
						mod:'res',
						ctr,
						// truck_plate_num:para.truck_plate_num,
						// start_time:para.start_time,
						// end_time:para.end_time,
						truck_tord_num:this.truck_tord_num,
					},
					callback:(res)=>{
						if(res.code !=0){
							this.$my.other.msg({
								type:'info',
								str:'无轨迹'
							});
							this.init1()
							let loadingInstance = Loading.service(this.options);
								this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
								loadingInstance.close();
							});
							return;
						}

						let data = res.msg
						if(data.list.length==0){
							this.$my.other.msg({
								type:'warning',
								str:'未读取到轨迹'
							});
							this.init1()
							let loadingInstance = Loading.service(this.options);
								this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
								loadingInstance.close();
							});
							return;
						}
						
						// data.list.unshift(this.case_lngLnt)
						// data.list.push(this.aim_lngLnt)
						//取出地图对象
						let AMap = this.AMap;

						//地图中间点
						let map_center=data.list[parseInt(data.list.length/2)]
						// let map_center=data.list[parseInt(data.list.length)]
						if(map_center){
							this.map.setCenter(new AMap.LngLat(map_center.lon,map_center.lat)); 
						}
						
						//标记列表
						let marker_list=[];
						// 创建一个起点 Icon
						var startIcon = new AMap.Icon({
							// 图标尺寸
							size: new AMap.Size(25, 34),
							// 图标的取图地址
							image:
							"//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
							// 图标所用图片大小
							imageSize: new AMap.Size(135, 40),
							// 图标取图偏移量
							imageOffset: new AMap.Pixel(-9, -3),
						});
						// 创建一个终点 icon
						var endIcon = new AMap.Icon({
							size: new AMap.Size(25, 34),
							image:
							"//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png",
							imageSize: new AMap.Size(135, 40),
							imageOffset: new AMap.Pixel(-95, -3),
						});
						//设置终点
						// let last_point=data.list[data.list.length-1];
						marker_list.push(new AMap.Marker({
							position:new AMap.LngLat(this.aim_lngLnt.lon,this.aim_lngLnt.lat),
							label:{
								content:`终点 ${this.aim_county}`, //设置文本标注内容
								direction:'right', //设置文本标注方位
							},
							icon: endIcon,
						}))
						//设置开始点
						marker_list.push(new AMap.Marker({
							position:new AMap.LngLat(this.case_lngLnt.lon,this.case_lngLnt.lat),
							label:{
								content:`起点 ${this.case_county}`, //设置文本标注内容
								direction: 'right' //设置文本标注方位
							},
							icon: startIcon,
						}))
						//线列表
						let path=[];
						let every_length=''
						let trajectory_length=''
						if(data.list.length>10){
							every_length=parseInt(data.list.length/10)
						}else{
							every_length=parseInt(data.list.length)
						}
					    if(data.list.length>10){
							trajectory_length=parseInt(data.list.length/20)
						}else{
							trajectory_length=parseInt(data.list.length)
						}
						
						
					    let that = this
						// let trajectory_list = []
						data.list.forEach((item,index) => {
							//置入线数据
							path.push(new AMap.LngLat(item.lon,item.lat));
							if(data.list.length>10){
								//中间点
								if(index%every_length==0){
									var center_point=item;
									that.marker_list.push(item)
									marker_list.push(new AMap.Marker({
										position:new AMap.LngLat(center_point.lon,center_point.lat),
										label:{
											content:center_point.date?center_point.date:that.$my.other.totime(center_point.timestamp), //设置文本标注内容
											direction:'top' //设置文本标注方位
										},
									}));
								}
							}else{
								var center_point=item;
									that.marker_list.push(item)
									marker_list.push(new AMap.Marker({
										position:new AMap.LngLat(center_point.lon,center_point.lat),
										label:{
											content:center_point.date?center_point.date:that.$my.other.totime(center_point.timestamp), //设置文本标注内容
											direction:'top' //设置文本标注方位
										},
									}));
							}

							// if(index%trajectory_length==0){
							// 	trajectory_list.push(item)
							// }
						});
						// for(var i=0;i<data.list.length;i++){

							//置入线数据
							// path.push(new AMap.LngLat(data.list[i].lon,data.list[i].lat));

							//中间点
							// if(i%every_length==0){
							// 	var center_point=data.list[i];
							// 	marker_list.push(new AMap.Marker({
							// 		position:new AMap.LngLat(center_point.lon,center_point.lat),
							// 		label:{
							// 			content:center_point.date, //设置文本标注内容
							// 			direction:'top' //设置文本标注方位
							// 		},
							// 	}));
							// }
							// if(i%trajectory_length==0){
							// 	trajectory_list.push(data.list[i])
							// }
							
						//}
					    // this.trajectory_list = trajectory_list
						//置入点
						this.map.add(marker_list);
						
						this.trajectory_type(0)
						//创建并置入线
						this.map.add(new AMap.Polyline({
							path: path,
							borderWeight:2,
							strokeColor: "#47aef3",
							strokeOpacity: 1,
							strokeWeight: 7,
							lineJoin: 'round',
							lineCap: 'round',
							showDir: true,
						})
						);
					}
				});
			},

			//关闭
			close(){
				window.onresize=null;
				this.$emit('close')
			}
		},
	};
</script>
<style lang="scss" scoped>
 .head-head{
	 margin: 10px 0px;
	 span{
		 margin-right: 10px;
		
	 }
 }
</style>