 <template>
	<el-dialog top="20vh" title="轨迹列表" width="800px" :visible.sync="this_is_show" @close="close" :modal="false">
       <div class="tab_height" style="width:100% ">
			<el-table :data="trajectory_list" :border="true" :stripe="true" size="small" height="100%">
				<el-table-column label="位置信息" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.addrs}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="司机手机号码" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{driver_info.name +'/'+driver_info.tel}}</div>
					</template>
				</el-table-column> -->
				<!-- <el-table-column label="定位类型" >
					<template slot-scope="scope">
						<div class="tab_line_item">线路{{scope.row.date}}</div>
					</template>
				</el-table-column> -->
				<el-table-column label="定位时间" >
					<template slot-scope="scope">
						<div class="tab_line_item">{{scope.row.time_text}}</div>
					</template>
				</el-table-column>
				<!-- <el-table-column label="操作" width="108">
					<template slot-scope="scope">

						<el-button @click="trajectory_type(2)" size="mini" type="text">轨迹</el-button>
					</template>
				</el-table-column> -->
			</el-table>
		</div>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
            trajectory_list:Array
		},
		data() {
			return {
				//页面是否显示
				this_is_show:false,
			}
		},
		watch:{
			is_show(new_data){
				if(new_data==0){
                    
					this.this_is_show=false
				}else {
                    console.log(this.trajectory_list)
                    this.this_is_show=true
				}
			},
		},
		methods: {
            // trajectory_type(n){
            //     	let that = this
            //         // let trajectory_list = []
            //         let date = res.msg.list[n]
            //         let addrs = ''
            //         if(n ==res.msg.list){
            //             this.is_show ++
            //             return
            //         }
            //         //经纬度转换详细地址
            //         const geocoder = new AMap.Geocoder();
            //         console.log(date)
            //         geocoder.getAddress([date.lon, date.lat], (status, result) => {
            //         if (status === 'complete' && result.info === 'OK') {
            //             addrs = result.regeocode.formattedAddress;
            //             that.trajectory_list.push({
            //                 addrs:addrs,
            //                 time_text:that.$my.other.totime(date.timestamp)
            //             })
            //             n++
            //             that.trajectory_type(n)
            //         } else {
            //             console.log('Failed to get the address information');
            //             n++
            //             that.trajectory_type(n)
            //         }
			// 	});
            // },
			se_driver_info(){
                this.$my.net.req({
                    data:{
                        mod:'truck_driver',
                        ctr:'driver_list_by_admin',
						...this.form
                    },callback:(data)=>{
                        this.form.name = data.list[0].app_user_info.name
                        this.form.user_num = data.list[0].app_user_info.user_num
                    }
                })
            },
		   close(){
			   this.form={
				   	user_tel:'',//司机手机
               		name:'',//司机姓名
					freight_allocation_mode:'',//分配方式
			   }
           },
		}
	}
</script>
<style lang="scss" scoped>
    .operation{
		color: rgb(18, 208, 221);
		margin-left: 5px;
	}
	.el-dialog-s{
		z-index: 11;
	}
	.under_tab{
		position: relative;
		height: 40px;
		border: 1px solid #eee;
		border-width: 0 1px 1px 1px;
	}
	.this_pay_total{
		position: absolute;
		right: 0px;
		width: 539px;
		border-left: 1px solid #eee;
		height: 40px;
		line-height: 40px;
		padding-left: 10px;
	}

	.big_tit{
		background-color: #eee;
		padding: 10px 20px;
		color: #666;
		font-weight: 600;
		font-size: 18px;
		margin-top: 20px;
	}
	.tab1_inner{
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
	}
	.el_inner_width{
		width:100%;
	}
	.payee{
		height: 30px;
		width: 30px;
		font-size: 20px;
		text-align: center;
		
		border-radius: 50%;
		color:rgb(0, 0, 0);
		.shou{
			margin-top: 4px;
		}
	}
	.data{
		margin-top: 5px;
		margin-left: 10px;
	}
	.red{
		background-color: red;
	}
	.green{
		background-color: #4CD964;
	}
    .button{
        text-align: center;
    }
</style>